.passanger{
    min-height: 100vh;
    
    /* background-color: red */
}
.passanger__title{
    color:var(--primary);
    font-weight: bold;
    padding-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.passanger__name{
    width: 100%;
    gap: 1rem;
}
.passanger__label{
    background-color: var(--bodyback);
    position: relative;
    top:0.8rem;
    left:0.5rem;
    width:fit-content;
    padding:0 0.5rem;
    color:gray
}
.passanger__input{
    background-color: transparent;
    border: 2px solid var(--outline);
    border-top: 1px solid var(--outline);

    padding: 0.5rem;
    border-radius: 0.5rem;
    width:100%;
    padding-left: 1rem;
}
.passanger__input__phone__code{
    background-color: transparent;
    border: none;
    border-right: 1px solid gray;
    width:5rem 
}
.passanger__input__phone{
    background-color: transparent;
    border: none;
    outline: none;
}
