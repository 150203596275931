.display{
    display: none;
}
.sign__left{
    display: none;
}
.sign__right{
    text-align: center;
    width: 100%;
    padding: 20px 40px;
    
}
.sign__right__title{
   color: var(--secondary); 
   font-size: 20px;
   font-weight: bolder;
   padding-bottom: 20px;
}

input{
    border-radius: 3px;
    border: solid 1px rgb(159, 158, 158);
}

/* .sign__right input{
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
} */
.in{
    display: flex;
    align-items: center;
}
.pro :nth-child(1){
    width: 50px !important;
}
.in :nth-child(1){
    border: solid 1px rgb(159, 158, 158);
    
    padding: 3px;
        height: 30px;
    width: 30px;
    margin-bottom: 10px;
    color: rgb(128, 128, 128);
    background-color: rgb(255, 255, 254);
    border-radius: 5px;
    
}

.sign__right__alrhave button{
    background-color: none;
    border: none;
}
.login__signupButton {
    width: 96%;
    background-color:var(--secondary) !important;
    color: white;
    font-weight: bold;
    border: none;
    padding: 5px 10px !important;
    border-radius: 5px;
    margin-bottom: 40px 0 !important;
    margin: 0 40px 10px 0 ;
    }
    .login__signupButton :hover{
        transform: scale(105%) !important;
        transition-duration: 0.15s;
            
            }
@media screen and (min-width: 576px) {

.sign__left{
    display: block;
    background-color: var(--primary);
    color: white;
    opacity: 90%;
    text-align: center;
}
.sign__left__head{
    
    font-size: 4vw;
    font-weight: bolder;
    padding: 50% 20px 0 20px;
    
}
.sign__left__moto{
    font-size: 2vw;
    padding: 50% 10px 10px 10px;


}
.sign__right{
    padding-right: 10px;
}
.sign__right__phone__para{
display: grid !important;
grid-template-columns: 25% 20% 50% !important;
column-gap: 5px;
}
.sign__right__phone__para div input{
    width: 100% !important;
    
    
}
.sign__right__phone__para  .selecta{
    width: 100% !important;
    
}
}
@media screen and (min-width: 768px) {
 
    .sign__left__head{
        padding: 40% 15% 0 15%;
        font-size: 3vw;
    }
    .sign__left__moto{
        font-size: 1.5vw;
        padding: 30% 15px 10px 15px;
    }
    .sign__right{
        margin: auto 0;
    }
    
}
