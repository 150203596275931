.details{
    background-color: var(--light);
}
.bus{
    background-color:var(--bodyback);  
    width: 67vw;
    border-radius: 1rem;
}
.bus__row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.bus__row{
    object-fit: cover;
    width: 67vw;
    /* background-color:var(--bodyback); */
    padding: 1vw;
}
.single__seat, .steer{
 
    width:11vw;
    height:11vw;
    border:none;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0;
    margin:1vw;
    padding-bottom: 1rem;
    background-color:var(--bodyback);  
}

.legend{
    position: sticky;
    bottom: 25vh;
    align-self: flex-end;
    width:20vw;
   
}
.payment__detail__total{
    border-top: 1px solid var(--bodyback);
    color: var(--primary);
}
.payment__detail__total div{
    color: var(--primary);
    font-weight: bold;
}
.detail__btn{
    background-color: var(--primary);
    color: var(--light);
    border: none;
    padding: 0.5rem 1rem;
    width:50%;
    text-decoration: none;
    text-align: center;
    
}
@media only screen and (min-width:768px) {
    .bus{
        background-color:var(--bodyback);  
        width: 14.5rem;
        border-radius: 1rem;
        /* height: fit-content; */
    }
    .bus__row{
        object-fit: cover;
        width: 14.5rem;
        /* background-color:var(--bodyback); */
        padding: 0 0.5rem;
    }
    .details__seat__Layout__whole{
  
        height: fit-content;
    }
    .single__seat,.steer, .legend button{
    width:2.5rem;
    height:2.5rem;
    border:none;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0;
    margin: 0.4rem 0;
    padding-bottom: 1rem;
    background-color:var(--bodyback);  
}
.legend{
    width:4rem;
    margin-left: 1rem;
}
.details__trip__detail{
    position: sticky;
    bottom: 20vh;
    align-self: flex-end;
    
   
}
.payment__detail__total div{
    font-size: 1.3rem;
}
.detail__btn{
    padding: 0.5rem 1rem;
    width:8rem
    
}
}