.header{
    background-color: var(--primary);
    font-family: 'Inria Sans', sans-serif;
}
.header a{
    text-decoration: none;
    color:var(--light) !important;
    padding: 0 1rem;
    
}
.header .logo__image{
height:2.5rem
}
.header .navbar-toggler, .header .navbar-toggler:focus{
    outline:0 !important;
    border:var(--primary);
    outline: none;
    box-shadow: none;
    color:red
}
.header .navbar-toggler-icon{
    background-image: url(../Assets/Icons/toggler_icon-01.png) !important;
}
@media only screen and (min-width:765px){
    .header .logo__image{
        height:3rem
        }
}