.modalbox{
    position: absolute;
    /* width:70vw; */
    /* top: 20; */
    /* margin-top: 20%; */
    width:100%;

}
.modal-content{
    padding: 0;
    border: 0;
}
.init{
    /* border:var(--bodyback) 5px solid !important; */
    padding: 15px;
}
.create-btn{
    color:var(--primary)
}
.button__text{
    color:var(--primary);
    position:static;
    z-index: 1000;
}