::-webkit-scrollbar {
    display: none;
}

.home {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    z-index: 1;

}

.path__icon {
    width: 0;
    height: 0;
    padding: 0 5px;
    border-radius: 50px;
    display: flex;
    justify-content: center;


}

.path__icon__inner {
    background-color: rgb(250, 250, 250);
    padding: 5px;
    border-radius: 20px;
    border: 2px solid rgb(189, 189, 189);
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 1000;
    top: -20px;
    left: calc(50vw);
    object-fit: cover;
    rotate: 45deg;

}

.focusedOrigin .path__icon {
    display: none;
}

.top__titles {
    padding-top: 4rem;
}

.background__image {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: calc(100vh - 7rem);
    /* height: calc((var(--vh, 1vh) * 100) - 7rem); */

    background-repeat: no-repeat;
    opacity: 0.2;
    /* background-image: url(../Assets/Images/backgroundBus.jpg); */
    /* background: url(../Assets/Images/backgroundBus.jpg), rgba(177, 177, 177, 0.8); */
    background: url(../Assets/Images/world.jpg), rgba(177, 177, 177, 0.8);
    background-size: cover;
    /* background: rgba(129, 126, 126, 0.8); */
    /* min-height: 100vh; */
    /* min-height: fill-available; */
    /* min-height: -webkit-fill-available; */

}

.home__top {
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end !important;

    height: calc(100vh - 6rem);
    height: calc((var(--vh, 1vh) * 100) - 6rem);

    /* height: calc((var(--vh, 1vh) * 100)); */



}


.sub_location .icon__image {
    width: 1.1rem;
    margin-right: 0.5rem;

}

.icon__image {
    width: 1.5rem;
    margin-right: 0.5rem;

}

.focusedOrigin {
    position: absolute;
    top: 2rem;
    z-index: 15;
}

.focusedOrigin .input {
    position: sticky;
    top: 2rem;
    z-index: 16;
}

.focusedOrigin .origin .locations__list {
    position: relative;
    z-index: 5;


    z-index: 10;
    overflow-y: scroll;
}

.focusedOrigin .destination,
.focusedOrigin .departing_date,
.focusedOrigin .tickets,
.focusedOrigin .search_trips {
    display: none;
}

.focusedDestination .origin,
.focusedDestination .departing_date,
.focusedDestination .tickets,
.focusedDestination .search_trips {
    display: none;
}

.focusedDestination {
    position: absolute;
    top: 2rem;
    z-index: 15;
    overflow: hidden;
}

.focusedDestination .input {
    position: sticky;
    top: -5rem;
}

.focusedDestination .origin .locations__list {
    position: relative;
    z-index: 5;


    z-index: 10;
    overflow-y: scroll;
}


.home__title {
    color: var(--primary);
    font-size: 4rem;
    font-weight: 800;

}

.home__subttitle {
    font-size: 1.5rem;
}

.main_location {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.5rem;
    list-style: none;
    padding: 1rem 0 0.25rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.sub_location {
    text-transform: capitalize;
    font-size: 1.2rem;
    list-style: none;
    cursor: pointer;
    padding: 0.5rem 2.5rem;
    display: flex;
    align-items: center;
    opacity: 0.9;
    margin-top: 2px;

}

.locations__list {
    max-height: 100vh;
    overflow: scroll;
    list-style: none;
    background-color: var(--light);
}

.sub_location:hover {
    color: var(--primary);
    font-size: 1.3rem;
    transition: ease-in-out .2s;
}


.home__inputs input {
    border: none;
    outline: none;
    height: 100%;
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--primary)
}

.home__inputs .input {
    width: 100vw;
    height: 5rem;
    background-color: var(--light);
    margin: 1px;
    border: none;
    padding: 1rem;

    /* border:1px solid green; */
}

.search_trips_btn {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    color: var(--light);
    font-size: 1.5rem;
    border: none;
}

.input__label {
    color: rgb(139, 139, 139);
}

.date__picker__input input {
    padding: 0.5rem 0;
    font-size: 1rem;
    /* border: 5px solid white; */
    /* outline:1px solid white; */
    height: 100%;
    overflow: hidden;


}

.date__picker__input fieldset {

    border: none;
}

.tickets button {
    background-color: var(--primary);
    border: none;
    border-radius: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--light);
    padding: 0;
    font-size: 1.5rem;

}

.tickets .ticket_counters {
    display: flex;
    align-items: center;

}

.ticket_counters {
    background-color: var(--light);
    width: 98vw;
    height: 10rem;
    background-color: var(--light);
    margin: 1px;
    border: none;
    padding: 1rem;
    position: absolute;
    bottom: 5rem;
    display: flex;
    align-items: stretch;
    justify-content: center;
    opacity: 0.8;
    border: 1px solid var(--primary) !important;
    margin-left: 1vw;
    border-radius: 2rem;
    display: none;
}

.counter__close__btn {
    width: 5rem !important;
    margin-top: 3rem;
    font-size: 1rem !important;
    background-color: var(--light) !important;
    color: var(--primary) !important;
    border: 2px solid var(--primary) !important;

}
 /* search resunts start  */

 /* search results top date start  */
.single__day__date {
    padding: 0.5rem 1rem;
    color: var(--primary);
    background-color: var(--light);
    border-bottom: 2px solid var(--primary) !important;
    font-weight: bold;
    text-align: center;
}
.search__results__dates__btns {
    overflow-x: scroll;
    width: 100%;
}

.search__results__dates__btns button {
    border: 1px solid var(--primary);
    text-transform: capitalize;
    padding: 0.6rem;
}

.search__results__dates__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search__results__dates__caption {
    position: relative;
    margin-bottom: -10px;
    border: 1px solid var(--primary);
    width: fit-content;
    padding: 0 10px;
    font-size: 0.8rem;
    background-color: var(--light);
    border-radius: 5px;
}

.caption_date {
    width: fit-content;
    width: 8rem;
    margin: 0;
}
 /* search results top date end  */

.search__single p {
    padding: 0;
    margin: 0;
    line-height: 1.1rem;
}

.search__results__single__day {
    background-color: var(--light);
}
.search__single__top{
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap:2rem;
    align-items: center;
    border-color: aliceblue;
    border: 2px solid white;
    
    padding:1rem;
    /* background-color: yellow; */
   
}
.search__single__top__left{
    text-align: left;
}

.search__single__top__route p{
    color: var(--primary);
}
.search__single__top__price{
font-size: 1.3rem;
font-weight: bold;
color: var(--primary);
justify-content: flex-end;



}
.search__single__top__price img {
width: 2rem;
padding: 0.3rem;
}

/* search single bottom  */
.search__single__bottom{
    padding: 1rem;
    border-bottom: 1px solid  rgb(180, 179, 179);

}
.search__single__bottom__title{
    color:var(--primary);
    font-weight: bold;
}
.search__single__bottom__content{
    display: grid;
    grid-template-columns: 1fr auto 2fr;
}
.search__single__bottom__divider{

    display: flex;
    flex-direction: column;

    justify-content: space-between;
    margin-bottom: 1.5rem;
   
    background-color: rgb(180, 179, 179);
    width:1px;
    align-items: center;

}
.search__single__bottom__pointer{
    width:1.5rem;
    height:1.5rem;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 0.5rem;
    margin-left: -0.75rem;
    flex-shrink: 0;
}

.search__single__bottom__left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1.5rem;
}
.search__single__departure__time{
    white-space: nowrap;
}
.search__single__from{
    padding-bottom: 5rem;
}
.search__single__from__location{
display: flex;
}
.search__single__trip__number{
    padding-left: 1.25rem;
}
.top-pointer{
    border:solid 3px rgb(19, 188, 61);
    
}
.bottom-pointer{
    border:solid 3px var(--primary);
    
}
.search__continue{
    padding-top: 1rem;
    text-align: center;
}
.search__continue__btn{
    background-color: var(--primary);
    color: var(--light);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;

}
@media only screen and (min-width:768px) {
    .background__image {
        height: calc(100vh);
    }

    .home__top {
        height: calc(100vh - 3rem)
    }

    .top__titles {
        padding-top: 0;
    }

    .home__subttitle {
        font-size: 2.5rem;
    }

    .locations__list {
        max-height: 40vh;
        overflow-y: scroll;
    }

    .home__inputs .input {
        width: 15vw;
        height:100px;
    }

    .focusedDestination .input,
    .focusedOrigin .input {
        width: auto
    }

    .tickets .ticket_counters {
        display: flex;
        align-items: center;
    }

    .focusedOrigin,
    .focusedDestination,
    .focusedOrigin .input,
    .focusedDestination .input {
        position: static;
    }

    .focusedOrigin .destination,
    .focusedOrigin .departing_date,
    .focusedOrigin .tickets,
    .focusedOrigin .search_trips {
        display: block;
    }

    .focusedDestination .origin,
    .focusedDestination .departing_date,
    .focusedDestination .tickets,
    .focusedDestination .search_trips {
        display: block;
    }

    .ticket_counters {
        width: 15vw;
        height: 10rem;
        bottom: 5rem;
        margin: 0;
        position: relative;
        margin-left: 0;
        border-radius: 0;

    }

    .path__icon{
        width: 0;
        height: 0;
        padding: 0 ;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        position: absolute;
        left:-22.5vw;
        margin-top:2.5rem;
    }
    .path__icon__inner { 
        padding: 2px;
        width: 30px;
        height: 30px;
        top: -5px;
    }
    .destination input, .destination .input__label{
        padding-left: 10px; 
    }
    .search__results {
        min-height: calc(100vh);
        background-color: var(--light);
    }

 
    .search__single{
        /* background-color: yellowgreen; */
    background-color: rgb(241, 240, 240);
    /* background-color: var(--light); */
        border: 2px solid rgb(207, 207, 207);
        border-top: none;
        border-radius: 1rem;
    }
    .search__single__top{
       display: none;
    }
.search__single__bottom__divider{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: -1.25rem;
    background-color: rgb(180, 179, 179);
    /* width:1px; */
    height: 1px;
    width:55%;
    align-items: center;
    z-index:0
}
    .search__single__bottom__content{
display: flex;
        flex-direction: column;
    }
    .search__single__bottom__left{
        width: 100%;
        display: grid;
        grid-template-columns: 1.3fr 1.2fr 1.5fr 1fr;
        gap:5vw;
        padding: 0;
      z-index: 5;
        
      
    }
    .search__single__bottom__right{
        width: 100%;
        display: grid;
        grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
        gap:5vw;
        margin: 0;
        z-index: 5;
        
      
    }
    .search__single__bottom{
        border: none;
    }
    .search__single__bottom__pointer{
        display: none;
    }
    .search__single__from{
        padding-bottom: 0;
    }
    .search__single__departure__time ,.search__single__arrival__time, .search__single__length{
    background-color: rgb(241, 240, 240);

        width: fit-content;
        /* padding-right: 0.5rem; */
    }
    .search__single__price{
        text-align: right;
        font-weight: 600;
        font-size: 1.3rem;
       
    }
    .search__continue{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }
}