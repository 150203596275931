.sign{
    
    /* margin: 100px auto; */
    min-height:calc(100vh - 40px);
    display: flex;
    align-items: center;

}
.sign__left{
    display: none;
}
.sign__right{
    text-align: center;
    width: 100%;
    padding: 20px 40px;
    
}
.sign__right__title{
   color: var(--secondary); 
   font-size: 20px;
   font-weight: bolder;
   padding-bottom: 20px;
}
input{
    border-radius: 3px;
    border: solid 1px rgb(159, 158, 158);
}

.sign__right input{
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
}
.in{
    display: flex;
    align-items: center;
}
.pro :nth-child(1){
    width: 50px !important;
}
.in :nth-child(1){
    border: solid 1px rgb(159, 158, 158);
    
    padding: 3px;
        height: 30px;
    width: 30px;
    margin-bottom: 10px;
    color: rgb(128, 128, 128);
    background-color: rgb(255, 255, 254);
    border-radius: 5px;
    
}
.sign__right__sign button{
    background-color: var(--primary);
    color: white;
    font-weight: bolder;
    padding: 0px 20px;margin-bottom: 10px;
}
.sign__right__alrhave button{
    background-color: none;
    border: none;
}   
.switcher{
    color: var(--secondary);
    font-weight: bold;
    border: none;
    background-color: transparent !important;
}
@media screen and (min-width: 576px) {
.sign{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    /* background-image: url(../../resources/images/Andmta_logo.png); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 90%;
    height:calc(100vh - 40px);

    justify-content: center;


}
.sign__left{
    display: block;
    background-color: var(--primary);
    color: white;
    opacity: 90%;
    text-align: center;
    height:calc(100vh - 40px)
}
.sign__left__head{
    
    font-size: 4vw;
    font-weight: bolder;
    padding: 40% 20px 0 20px;
    color:white
    
}
.sign__right{
    padding-right: 10px;
}
}
@media screen and (min-width: 768px) {
    .sign{
        
        /* width: 900px; */
    }
    .sign__left__head{
        padding: 40% 15% 0 15%;
        font-size: 3vw;
    }
    .sign__right{
        margin: auto 0;
    }
    .signin{
        background-color: none;
    }
}
