.signin__right__alrhave{
    background-color: none !important;
    border: none;
    color: var(--secondary);
    margin-top: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}
.remember{
    text-align: center;
    padding-top: 25px;
}
.signup__right__signup {
width: 98%;
background-color:var(--primary) ;
color: white;
font-weight: bold;
border: none;
padding: 5px 10px;
border-radius: 5px;
margin-bottom: 40px 0 !important;
margin: 0 40px 10px 0 ;

}
.signup__right__signup:hover{
-webkit-transform: scale(105%) ;
transition-duration: 0.15s;
    
    }
.signin__right__title{
    color: var(--secondary); 
    font-size: 20px;
    font-weight: bolder;
    padding-bottom: 20px;
 }